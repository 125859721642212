import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../../layouts/default/layout'
import FlexContent from '../../components/flexcontent/flexcontent'
import SEO from '../../components/seo'
import './work-item.scss'
import Wysiwyg from '../../components/wysiwyg/Wysiwyg'
import CaretDownIcon from '../../components/icons/CaretDown'

const WorkItem = ({ data: { wordpressWpWorks, allWordpressWpWorks } }) => {
    const work = wordpressWpWorks
    const next_work = allWordpressWpWorks && allWordpressWpWorks.edges && allWordpressWpWorks.edges.length
        ? allWordpressWpWorks.edges[0].node
        : null
    const { title, content, featuredMedia, featuredMediaMobile, acf } = work
    return (
        <Layout theme={'dark'} headerSpacing={false}>
            <SEO
                yoast={work.yoast}
                title={work.title}
                description={work.content}
                dateModified={work.modified}
                datePublished={work.date}
            />
            <main className="WorkItem">
                <header>
                    {featuredMedia && (
                        <Img
                            className="featured-media d-non-mobile"
                            critical
                            fadeIn={false}
                            fluid={
                                featuredMedia.localFile.childImageSharp.fluid
                            }
                            alt={featuredMedia.alt_text}
                            title={featuredMedia.title}
                        />
                    )}
                    {featuredMediaMobile && (
                        <React.Fragment>
                            <Img
                                className="featured-media d-mobile"
                                critical
                                fadeIn={false}
                                fluid={
                                    featuredMediaMobile.localFile
                                        .childImageSharp.fluid
                                }
                                alt={featuredMediaMobile.alt_text}
                                title={featuredMediaMobile.title}
                            />
                            <div className="mobile-read-more d-mobile">
                                <CaretDownIcon />
                            </div>
                        </React.Fragment>
                    )}
                    <div className="header-overlay px-yd text-center">
                        <h1
                            dangerouslySetInnerHTML={{
                                __html: title,
                            }}
                        />
                    </div>
                </header>
                {content && (
                    <section className="work-intro mt-yd px-yd">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 col-md-8 offset-md-2 col-lg-10 offset-lg-1">
                                    <Wysiwyg className="type-subtitle">
                                        {content}
                                    </Wysiwyg>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
                <FlexContent contents={acf.contents_portfolio} />
                {next_work && (
                    <section className="next-work">
                        <React.Fragment>
                            <div className="d-mobile">
                                {next_work.featuredMediaMobile ? (
                                    <Img
                                        fluid={
                                            next_work.featuredMediaMobile
                                                .localFile.childImageSharp.fluid
                                        }
                                        alt={
                                            next_work.featuredMediaMobile
                                                .alt_text
                                        }
                                        title={
                                            next_work.featuredMediaMobile.title
                                        }
                                    />
                                ) : (
                                    <div
                                        className="image-placeholder"
                                        style={{
                                            height: `100vw`,
                                            width: '100%',
                                        }}
                                    />
                                )}
                            </div>
                            <div className="d-non-mobile">
                                {next_work.featured_media ? (
                                    <Img
                                        fluid={
                                            next_work.featured_media.localFile
                                                .childImageSharp.fluid
                                        }
                                        alt={next_work.featured_media.alt_text}
                                        title={next_work.featured_media.title}
                                    />
                                ) : (
                                    <div
                                        className="image-placeholder"
                                        style={{ height: 300, width: '100%' }}
                                    />
                                )}
                            </div>
                            <Link
                                className="next-work-link d-flex align-items-center justify-content-center flex-column px-yd text-center"
                                to={`/projects/${next_work.slug}`}
                            >
                                <div className="type-link">{`Next`}</div>
                                <div
                                    className="type-h1"
                                    dangerouslySetInnerHTML={{
                                        __html: next_work.title,
                                    }}
                                />
                            </Link>
                        </React.Fragment>
                    </section>
                )}
            </main>
        </Layout>
    )
}

export default WorkItem

export const pageQuery = graphql`
    query($id: String!, $wordpress_id: Int!, $menu_order: Int!) {
        wordpressWpWorks(id: { eq: $id }) {
            id
            title
            wordpress_id
            content
            ...YoastWorks
            featuredMedia: featured_media {
                alt_text
                title
                localFile {
                    childImageSharp {
                        fluid(maxWidth: 2000, maxHeight: 1000) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            featuredMediaMobile: featured_media {
                alt_text
                title
                localFile {
                    childImageSharp {
                        fluid(maxWidth: 1440, maxHeight: 2560 cropFocus: CENTER) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            acf {
                contents_portfolio {
                    __typename
                    ... on WordPressAcf_image {
                        ...FlexContentImageFragment
                    }
                    ... on WordPressAcf_two_image {
                        ...FlexContentTwoImageFragment
                    }
                    ... on WordPressAcf_quote {
                        ...FlexContentQuoteFragment
                    }
                    ... on WordPressAcf_text_heading {
                        ...FlexContentTextHeadingFragment
                    }
                    ... on WordPressAcf_text_and_image {
                        ...FlexContentTextImageFragment
                    }
                    ... on WordPressAcf_two_text {
                        ...FlexContentTwoTextFragment
                    }
                    ... on WordPressAcf_code {
                        ...FlexContentCodeFragment
                    }
                    ... on WordPressAcf_single_video {
                        ...FlexContentVideoFragment
                    }
                    ... on WordPressAcf_two_video {
                        ...FlexContentTwoVideoFragment
                    }
                }
            }
        }
        allWordpressWpWorks(
            limit: 1
            filter: {
                wordpress_id: { ne: $wordpress_id }
                menu_order: { gt: $menu_order }
                workstatus: { elemMatch: { name: { nin: "Archived" } } }
            }
            sort: { fields: [menu_order], order: ASC }
        ) {
            edges {
                node {
                    title
                    slug
                    featured_media {
                        alt_text
                        title
                        localFile {
                            childImageSharp {
                                fluid(
                                    maxWidth: 2000
                                    maxHeight: 1000
                                    cropFocus: CENTER
                                ) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    featuredMediaMobile: featured_media {
                        alt_text
                        title
                        localFile {
                            childImageSharp {
                                fluid(
                                    maxWidth: 1000
                                    maxHeight: 1000
                                    cropFocus: CENTER
                                ) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
