import React from 'react'

export default () => (
    <svg
        enableBackground="new 0 0 100 100"
        version="1.1"
        viewBox="0 0 100 100"
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <polygon
            fill="#FFFFFF"
            points="23.1,34.1 51.5,61.7 80,34.1 81.5,35 51.5,64.1 21.5,35 23.1,34.1 "
        />
    </svg>
)
